import React, { useEffect, useRef, useState } from "react";
import { Button } from "../Button/Buttons";
import { HiOutlineArrowUp, HiOutlineChatAlt2, HiX } from "react-icons/hi";
import { Modal } from "../Modal/Modals";
import "./Chat.scss";
import Header from "../Header/Header";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { firestore } from "../../database/utils";
import firebase from "firebase/compat/app";
import {
  addMessageStart,
  fetchMessagesStart,
  updateMessageStart,
} from "../../redux/messages/messages.actions";
import moment from "moment/moment";
import { Input } from "../Input/Input";
import { addNotificationStart } from "../../redux/notifications/notifications.actions";
import { Link } from "react-router-dom";

const messagesSelector = (state) => state.messagesData.messages;
const messageSelector = (state) => state.messagesData.message;
const currentUserSelector = (state) => state.user.currentUser;
const usersSelector = (state) => state.usersData.users;

const mapState = createSelector(
  [messagesSelector, messageSelector, currentUserSelector, usersSelector],
  (messages, message, currentUser, users) => ({
    messages,
    message,
    currentUser,
    users,
  })
);

function ChatTimestamp({ children, hideSentAt }) {
  if (hideSentAt) return null;
  return <>{children}</>;
}

export const ChatMessage = ({
  currentUser,
  message,
  receiverPhotoUrl,
  receiverID,
}) => {
  const [hideSentAt, setHideSentAt] = useState(true);
  const toggleSentAt = () => setHideSentAt(!hideSentAt);

  const configSentAt = {
    hideSentAt,
    toggleSentAt,
  };

  if (!message.sentAt || !message.sentAt.toDate) return null;

  return (
    <div
      className={`chatMessage ${
        currentUser.id === message.senderId ? "sender" : "receiver"
      }`}
    >
      {currentUser.id === message.receiverId ? (
        <Link to={`/user/${receiverID}`}>
          <img src={receiverPhotoUrl} alt={currentUser.username} />
        </Link>
      ) : null}
      <div className="chatMessageText">
        <p onClick={() => toggleSentAt()}>{message.text}</p>
        <ChatTimestamp {...configSentAt}>
          <small {...configSentAt}>
            {moment(message.sentAt.toDate()).fromNow()}
          </small>
        </ChatTimestamp>
      </div>
    </div>
  );
};

export default function Chat({ user }) {
  const dispatch = useDispatch();
  const { currentUser, messages } = useSelector(mapState);
  const { data: messagesData } = messages;
  const [currentMessage, setCurrentMessage] = useState("");

  useEffect(
    (messagesData) => {
      dispatch(fetchMessagesStart());
    },
    [dispatch, messagesData]
  );

  //MODAL
  const [hideModal, setHideModal] = useState(true);
  const toggleModal = () => setHideModal(!hideModal);
  const configModal = {
    hideModal,
    toggleModal,
  };

  const sendMessage = (e) => {
    e.preventDefault();

    const chatIdSenderFirst = `${currentUser.id}_${user.documentID}`;
    const chatIdReceiverFirst = `${user.documentID}_${currentUser.id}`;

    const updateOrCreateChat = (message) => {
      const chatRef = firestore.collection("messages").doc(message);

      chatRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            chatRef.update({
              messages: firebase.firestore.FieldValue.arrayUnion({
                text: currentMessage,
                sentAt: new Date(),
                senderId: currentUser.id,
                receiverId: user.documentID,
                isViewed: false,
              }),
            });
            dispatch(fetchMessagesStart());
          } else {
            chatRef.set({
              messages: [
                {
                  text: currentMessage,
                  sentAt: new Date(),
                  senderId: currentUser.id,
                  receiverId: user.documentID,
                  isViewed: false,
                },
              ],
              chatInitiator: currentUser.id,
              chatReceiver: user.documentID,
              createdDate: new Date(),
            });
          }
        })
        .catch((error) => {
          console.error("Error updating or creating chat document: ", error);
        });
    };

    firestore
      .collection("messages")
      .doc(chatIdSenderFirst)
      .get()
      .then((doc) => {
        if (doc.exists) {
          updateOrCreateChat(chatIdSenderFirst);
        } else {
          firestore
            .collection("messages")
            .doc(chatIdReceiverFirst)
            .get()
            .then((doc) => {
              if (doc.exists) {
                updateOrCreateChat(chatIdReceiverFirst);
              } else {
                updateOrCreateChat(chatIdSenderFirst);
              }
            });
        }
      })
      .catch((error) => {
        console.error("Error checking chat documents: ", error);
      });
    dispatch(
      addNotificationStart({
        receiverID: user.documentID,
        senderID: currentUser.id,
        text: "sent you a message",
        isViewed: false,
      })
    );
    setCurrentMessage("");
  };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messagesData]);

  const isMessageValid = (message) => {
    return message.trim().length > 0;
  };

  useEffect(() => {
    if (!hideModal) {
      // Update messages when the modal opens
      messagesData.forEach((message) => {
        message.messages.forEach((msg, msgIndex) => {
          if (!msg.isViewed && msg.receiverId === currentUser.id) {
            markMessageAsViewed(message.documentID, msgIndex);
          }
        });
      });
    }
  }, [hideModal, messagesData, currentUser.id]);

  const markMessageAsViewed = (chatId, messageIndex) => {
    const chatRef = firestore.collection("messages").doc(chatId);

    chatRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          const messagesArray = doc.data().messages;
          const message = messagesArray[messageIndex];

          if (message && !message.isViewed) {
            chatRef
              .update({
                messages: firebase.firestore.FieldValue.arrayRemove(message),
              })
              .then(() => {
                const updatedMessage = { ...message, isViewed: true };

                chatRef
                  .update({
                    messages:
                      firebase.firestore.FieldValue.arrayUnion(updatedMessage),
                  })
                  .then(() => {
                    console.log("Message marked as viewed.");
                  })
                  .catch((error) => {
                    console.error("Error adding updated message: ", error);
                  });
              })
              .catch((error) => {
                console.error("Error removing old message: ", error);
              });
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching chat document: ", error);
      });
  };
  return (
    <>
      <Button
        title={<HiOutlineChatAlt2 />}
        hierarchy="favorite"
        type="button"
        handleClick={() => toggleModal()}
      />
      <Modal extendedClass="chat" {...configModal}>
        <div className="chat">
          <br />
          <Header
            title={
              <>
                <Link to={`/user/${user.documentID}`}>{user.displayName}</Link>
                <button onClick={() => toggleModal()}>
                  <HiX />
                </button>
              </>
            }
          />
          <div className="chatMessages">
            {messagesData &&
              messagesData.map((message, index) => {
                const ids = message.documentID.split("_");
                const includesCurrentUserAndReceiver =
                  ids.includes(currentUser.id) && ids.includes(user.documentID);

                if (!includesCurrentUserAndReceiver) {
                  return null;
                }

                return (
                  <React.Fragment key={index}>
                    {message.messages &&
                      message.messages.map((message, msgIndex) => {
                        if (!message.sentAt || !message.sentAt.toDate)
                          return null;

                        return (
                          <ChatMessage
                            currentUser={currentUser}
                            message={message}
                            receiverPhotoUrl={user.photoURL}
                            receiverID={user.documentID}
                            key={msgIndex}
                          />
                        );
                      })}
                  </React.Fragment>
                );
              })}
          </div>
          {/* <ChatControl/> */}
          <form onSubmit={sendMessage}>
            <div className="chatControl">
              <Input
                value={currentMessage}
                handleChange={(e) => setCurrentMessage(e.target.value)}
                type="text"
                placeholder="Message"
              />
              <button
                disabled={!isMessageValid(currentMessage)}
                onClick={sendMessage}
              >
                <HiOutlineArrowUp />
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}
