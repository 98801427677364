import React from "react";
import "./FlexBox.scss";

export function FlexBox({
  children,
  align,
  wrap,
  justify,
  margin,
  width,
  mobile,
  scroll,
  extraClass,
}) {
  return (
    <div
      className={`flexBox${
        align === "start"
          ? " alignStart"
          : align === "center"
          ? " alignCenter"
          : ""
      }${wrap === true ? " flexWrap" : ""}${
        justify === "center"
          ? " justifyCenter"
          : justify === "space"
          ? " justifySpace"
          : justify === "start"
          ? " justifyStart"
          : justify === "end"
          ? " justifyEnd"
          : ""
      }${
        margin === "none"
          ? " marginNone"
          : margin === "autoHorizontal"
          ? " marginAutoHor"
          : ""
      }${width === "fit" ? " widthFit" : width === "full" ? " widthFull" : ""}${
        mobile === true ? " flexMobileNone" : ""
      }${scroll === true ? " scroll" : ""}${
        extraClass ? ` ${extraClass}` : ""
      }`}
    >
      {children}
    </div>
  );
}
