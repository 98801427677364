import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../components/Button/Buttons";
import { HiOutlineStar, HiStar } from "react-icons/hi";
import { removeUserStart, saveUserStart } from "../../redux/user/user.actions";
import { createSelector } from "reselect";

const currentUserSelector = (state) => state.user.currentUser;
const mapState = createSelector([currentUserSelector], (currentUser) => ({
  currentUser,
}));

export default function FavoriteButton({ favUserID, userPage }) {
  const dispatch = useDispatch();
  const { currentUser } = useSelector(mapState);
  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    if (currentUser && currentUser.favoriteUsers && favUserID) {
      setIsFavorite(currentUser.favoriteUsers.includes(favUserID));
    }
  }, [currentUser, currentUser.favoriteUsers, favUserID]);

  const handleToggleFavorite = () => {
    if (isFavorite) {
      dispatch(removeUserStart(favUserID));
    } else {
      dispatch(saveUserStart(favUserID));
    }
    setIsFavorite(!isFavorite);
  };

  return (
    <>
      {!userPage ? (
        <Button
          title={
            isFavorite ? <HiStar className="favorite" /> : <HiOutlineStar />
          }
          hierarchy="favorite"
          handleClick={handleToggleFavorite}
        />
      ) : (
        <Button
          title={isFavorite ? "Favorite" : "Add to favorites"}
          hierarchy={isFavorite ? "primary" : "secondary"}
          handleClick={handleToggleFavorite}
        />
      )}
    </>
  );
}
