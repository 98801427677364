import React, { useEffect, useState } from "react";
import "./Users.scss";
import UserCard from "./UserCard";

import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { fetchUsersStart } from "../../redux/users/users.actions";
import moment from "moment";

const currentUserSelector = (state) => state.user.currentUser;
const usersSelector = (state) => state.usersData.users;

const mapState = createSelector(
  [currentUserSelector, usersSelector],
  (currentUser, users) => ({
    currentUser,
    users,
  })
);

export default function Users() {
  const dispatch = useDispatch();

  const { currentUser, users } = useSelector(mapState);
  const [userDistances, setUserDistances] = useState({});
  const { data: usersData } = users;

  useEffect(() => {
    dispatch(fetchUsersStart());
  }, [dispatch, fetchUsersStart]);

  return (
    <>
      {currentUser ? (
        <div className="usersResults">
          <div className="usersResultsScroll">
            {usersData &&
              usersData.map((user, index) => {
                //SHOW ONLY IF USER IS LOVER
                if (user.documentID === currentUser.id) return null;
                if (!user.userRoles.includes("lover")) return null;
                //RESTRICT IF USER IT DIDNT FILLED ITS INFO
                if (
                  [
                    user.height,
                    user.weight,
                    user.birthDate,
                    user.gender,
                    user.sexualOrientation,
                    user.bodyType,
                  ].some(
                    (field) =>
                      field === null || field === undefined || field === ""
                  )
                ) {
                  return null;
                }

                //CURRENT USER DISPLAY BY FILTERS
                const distance = userDistances[user.documentID];
                let distanceDisplay;
                if (distance !== undefined) {
                  if (distance < 1) {
                    distanceDisplay = "less than 1 km away";
                  } else {
                    distanceDisplay = distance.toFixed(2) + " km away";
                  }
                } else {
                  distanceDisplay = "Loading...";
                }
                if (
                  (currentUser.resultsFilter &&
                    currentUser.resultsFilter.valueGender !== "" &&
                    currentUser.resultsFilter.valueGender !== user.gender) ||
                  (currentUser.resultsFilter &&
                    currentUser.resultsFilter.valueSexualOrientation !== "" &&
                    currentUser.resultsFilter.valueSexualOrientation !==
                      user.sexualOrientation)
                ) {
                  return null;
                }

                if (!currentUser.resultsFilter) {
                  const birthDate =
                    user.birthDate &&
                    user.birthDate.toDate &&
                    user.birthDate.toDate();
                  const userAge = birthDate
                    ? moment().diff(moment(birthDate), "years")
                    : null;

                  return (
                    <UserCard
                      key={user.documentID}
                      user={user}
                      documentID={user.documentID}
                      currentUser={currentUser}
                      userAge={userAge}
                    />
                  );
                }

                const birthDate =
                  user.birthDate &&
                  user.birthDate.toDate &&
                  user.birthDate.toDate();
                const userAge = birthDate
                  ? moment().diff(moment(birthDate), "years")
                  : null;
                if (
                  !currentUser.resultsFilter ||
                  !currentUser.resultsFilter.valueMinAge ||
                  !currentUser.resultsFilter.valueMaxAge ||
                  (userAge &&
                    (userAge < currentUser.resultsFilter.valueMinAge ||
                      userAge > currentUser.resultsFilter.valueMaxAge))
                ) {
                  return null;
                }

                if (
                  !user.birthDate &&
                  user.birthDate === null &&
                  user.birthDate === ""
                )
                  return null;

                return (
                  <UserCard
                    key={user.documentID}
                    user={user}
                    documentID={user.documentID}
                    currentUser={currentUser}
                    distanceDisplay={distanceDisplay}
                    userAge={userAge}
                  />
                );
              })}
          </div>
        </div>
      ) : (
        <div className="usersResults">
          <div className="usersResultsScroll">
            {usersData &&
              usersData.map((user, index) => {
                //SHOW ONLY IF USER IS LOVER
                if (!user.userRoles.includes("lover")) return null;
                //RESTRICT IF USER IT DIDNT FILLED ITS INFO
                if (
                  [
                    user.height,
                    user.weight,
                    user.birthDate,
                    user.gender,
                    user.sexualOrientation,
                    user.bodyType,
                  ].some(
                    (field) =>
                      field === null || field === undefined || field === ""
                  )
                ) {
                  return null;
                }

                const birthDate =
                  user.birthDate &&
                  user.birthDate.toDate &&
                  user.birthDate.toDate();
                const userAge = birthDate
                  ? moment().diff(moment(birthDate), "years")
                  : null;

                if (
                  !user.birthDate &&
                  user.birthDate === null &&
                  user.birthDate === ""
                )
                  return null;

                return (
                  <UserCard
                    key={user.documentID}
                    user={user}
                    documentID={user.documentID}
                    currentUser={currentUser}
                    userAge={userAge}
                  />
                );
              })}
          </div>
        </div>
      )}
    </>
  );
}
