import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

//DB
import { fetchUserStart, setUser } from "../../redux/users/users.actions";
import { useDispatch } from "react-redux";

//COMPONENTS
import Header from "../../components/Header/Header";
import { FlexBox } from "../../components/FlexBox/FlexBox";
import FavoriteButton from "../Favorites/FavoriteButton";
import Chat from "../../components/Chat/Chat";
import { GeoMatch } from "../../components/Geolocator";
//ASSETS
import { TbScale } from "react-icons/tb";
import { CgGenderFemale, CgGenderMale, CgSandClock } from "react-icons/cg";
import { TbMapPin } from "react-icons/tb";
import { LuRuler } from "react-icons/lu";
import { RiUser4Line } from "react-icons/ri";
import {
  HiOutlineBan,
  HiOutlineChatAlt2,
  HiOutlineCheckCircle,
  HiOutlineGlobe,
  HiOutlineStar,
} from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { IoBodyOutline } from "react-icons/io5";
import { Button } from "../../components/Button/Buttons";
import { ModalBottom } from "../../components/Modal/Modals";

export default function UserCard({ user, documentID, currentUser, userAge }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(fetchUserStart(documentID));
    return () => {
      dispatch(setUser({}));
    };
  }, [dispatch, documentID]);
  //MODAL
  const [hideModalBottom, setHideModalBottom] = useState(true);
  const toggleModalBottom = () => setHideModalBottom(!hideModalBottom);
  const configModal = {
    hideModalBottom,
    toggleModalBottom,
  };
  return (
    <>
      <div className="cardUser">
        {currentUser ? (
          <Link to={`/user/${documentID}`}>
            <img
              src={
                user.photoURL ? user.photoURL : "/assets/userPleaceholder.png"
              }
              alt={user.displayName}
            />
          </Link>
        ) : (
          <Link to="/lovers" onClick={() => toggleModalBottom()}>
            <img
              src={
                user.photoURL ? user.photoURL : "/assets/userPleaceholder.png"
              }
              alt={user.displayName}
            />
          </Link>
        )}
        <div className="cardUserInfo">
          <Header
            title={
              <>
                <FlexBox justify="space" align="start" width="full">
                  {currentUser ? (
                    <Link to={`/user/${documentID}`}>
                      <small className="textSmall">{user.username}</small>
                      {user.displayName}
                    </Link>
                  ) : (
                    <Link to="/lovers" onClick={() => toggleModalBottom()}>
                      <small className="textSmall">{user.username}</small>
                      {user.displayName}
                    </Link>
                  )}
                  <p
                    className={`basicInfo${
                      user.isAvailable === true ? " available" : " busy"
                    }`}
                  >
                    {user.isAvailable === true ? (
                      <HiOutlineCheckCircle />
                    ) : (
                      <HiOutlineBan />
                    )}
                    {user.isAvailable === true ? t("Available") : t("Busy")}
                  </p>
                </FlexBox>
              </>
            }
          />
          <FlexBox wrap={true}>
            <p className="basicInfo">
              <TbMapPin />
              <GeoMatch driverLat={user.lat} driverLon={user.lon} />
            </p>
            <p className="basicInfo">
              <LuRuler />
              {user.height}cm
            </p>
            <p className="basicInfo">
              <TbScale />
              {user.weight}kg
            </p>
            {user.sexualOrientation ? (
              <p className="basicInfo">
                <RiUser4Line />
                {user.sexualOrientation !== null
                  ? t(`${user.sexualOrientation}`)
                  : null}
              </p>
            ) : null}
            {user.gender ? (
              <p className="basicInfo capitalize">
                {user.gender === "Female" ? (
                  <CgGenderFemale />
                ) : (
                  <CgGenderMale />
                )}
                {t(`${user.gender}`)}
              </p>
            ) : null}
            {user.birthDate && user.birthDate !== null ? (
              <p className="basicInfo">
                <CgSandClock />
                {userAge} {t("years")}
              </p>
            ) : null}
            {user.bodyType && user.bodyType !== null ? (
              <p className="basicInfo">
                <IoBodyOutline />
                {t(`${user.bodyType}`)}
              </p>
            ) : null}
            {user.ethnicity ? (
              <p className="basicInfo">
                <HiOutlineGlobe />
                {user.ethnicity}
              </p>
            ) : null}
          </FlexBox>
        </div>
        <div className="userCta">
          {currentUser ? (
            <>
              <FavoriteButton
                currentUser={currentUser}
                user={user}
                favUserID={user.documentID}
              />
              <Chat user={user} />
            </>
          ) : (
            <>
              <Button
                title={<HiOutlineStar />}
                hierarchy="favorite"
                handleClick={() => toggleModalBottom()}
              />
              <Button
                title={<HiOutlineChatAlt2 />}
                hierarchy="favorite"
                type="button"
                handleClick={() => toggleModalBottom()}
              />
            </>
          )}
        </div>
      </div>
      <ModalBottom {...configModal}>
        <br />
        <h3>{t("Become a member")}</h3>
        <br />
        <p>To access this area you have to login</p>
        <br />
        <Link to="/get-started" onClick={() => toggleModalBottom()}>
          <Button title={t("Get started")} hierarchy="secondary" />
        </Link>
        <Link to="/login" onClick={() => toggleModalBottom()}>
          <Button title={t("I already have an account")} hierarchy="tertiary" />
        </Link>
        <br />
        <br />
      </ModalBottom>
    </>
  );
}
